/* layout */
/*
#bodypadding .a-wrap {
  position: relative;
  z-index: 999990;
}
#bodypadding .a-wrap::after {
  display: block;
  content: "";
  height: 20px;
  width: 100px;
}
.screen-button {
  position: relative;
  z-index: 999991;
}
*/

/* custom banner overrides */
.custom-banner .vertical-slot-wrapper,
.custom-banner #custom-banner {
  height: 394px !important;
}
